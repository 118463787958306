import React from "react"
import VoiceGenderPage from '@common/loggedIn/VoiceGenderPage'
import { graphql } from 'gatsby'

const FemaleVoices = (props) => (<VoiceGenderPage gender="Female" allStory={props.data.allStory} allVoice={props.data.allVoice}/>)

export default FemaleVoices; 

export const query = graphql`
  query FemaleVoices($lang: String, $isEn: Boolean!, $isEs: Boolean!, $isDe: Boolean!){
    allStory(filter: {languages: {in: [$lang]}}) {
      edges {
        node {
          ...allStoryInfo
        }
      }
    }
    allVoice(filter: {language: {eq: $lang}}) {
      edges {
        node {
          id
          name
          gender
          language
        }
      }
    }
    dummyToUseVarInFragment: allStory(filter: {id: {eq: "nevertrue"}}){
      edges {
        node{
          id @include(if: $isEs)
          id @include(if: $isEn)
          id @include(if: $isDe)
        }
      }
    }
  }
`
